
 import * as THREE from 'three'

import RippleShaderVertex from '../glsl/RippleShader.vs';
import RippleShaderFragment from '../glsl/RippleShader.fs';

export const RippleShader = {

    uniforms: {

        // uniform sampler2D uImage;

        "tDiffuse": new THREE.Uniform(),
        "uZoomDelta": new THREE.Uniform(0.2),
        "resolution": new THREE.Uniform(new THREE.Vector2(0, 0)),
        "uImageSize": new THREE.Uniform(new THREE.Vector2(0, 0)),
        "uMouse": new THREE.Uniform(new THREE.Vector2(0, 0)),

    },

    vertexShader: RippleShaderVertex,

    fragmentShader: RippleShaderFragment

}