import './style/main.css'
import './style/view.css'

import * as THREE from 'three';
import { YF, Loader } from './YF/index.js';

import BACKGROUND_SOUND from './assets/audios/background_sound.wav';
import YF_LOGO from './assets/images/yf.logo.png';

// limbo page
import LIMBO_A_MEDIA from './assets/videos/limbo_a.mp4';
import LIMBO_B_MEDIA from './assets/videos/limbo_b.mp4';

// import LIMBO_IMAGE from './assets/images/limbo/limbo.jpg';
import COOA_IMAGE from './assets/images/limbo/cooa.webp';
import LIMBO_TEXT_0_IMAGE from './assets/images/limbo/text_0.webp';
import LIMBO_TEXT_1_IMAGE from './assets/images/limbo/text_1.webp';
import LIMBO_TEXT_2_IMAGE from './assets/images/limbo/text_2.webp';
import LIMBO_TEXT_3_IMAGE from './assets/images/limbo/text_3.webp';
import LIMBO_TEXT_4_IMAGE from './assets/images/limbo/text_4.webp';
import LIMBO_TEXT_5_IMAGE from './assets/images/limbo/text_5.webp';
import LIMBO_TEXT_6_IMAGE from './assets/images/limbo/text_6.webp';
import LIMBO_TEXT_7_IMAGE from './assets/images/limbo/limbo.gif';

// trailer page
import TRAILER_MEDIA from './assets/videos/myrj.mp4';
// import TRAILER_MEDIA from './assets/videos/trailer.mp4';
// import TRAILER_IMAGE from './assets/images/trailer/trailer.jpg';
import TRAILER_BACKGROUND from './assets/images/trailer/trailer.webp';
import TRAILER_CORNER_0_IMAGE from './assets/images/trailer/corner_0.webp';
import TRAILER_CORNER_1_IMAGE from './assets/images/trailer/corner_1.webp';
import TRAILER_CORNER_2_IMAGE from './assets/images/trailer/corner_2.webp';
import TRAILER_CORNER_3_IMAGE from './assets/images/trailer/corner_3.webp';
import TRAILER_CAUTION_IMAGE from './assets/images/trailer/caution.webp';
import TRAILER_TEXT_0_IMAGE from './assets/images/trailer/text_0.webp';
import TRAILER_TEXT_1_IMAGE from './assets/images/trailer/text_1.webp';
import TRAILER_TEXT_2_IMAGE from './assets/images/trailer/text_2.webp';
import TRAILER_TEXT_3_IMAGE from './assets/images/trailer/text_3.webp';
import TRAILER_TEXT_4_IMAGE from './assets/images/trailer/text_4.webp';

// ncs page
import NCS_MEDIA from './assets/videos/ncs.mp4';
import NCS_IMAGE from './assets/images/ncs/ncs.webp';
import NCS_CARD_A_IMAGE from './assets/images/ncs/ncs_a.webp';
import NCS_CARD_B_IMAGE from './assets/images/ncs/ncs_b.webp';

// ouh page
import OUH_MEDIA from './assets/videos/ouh.mp4';
import OUH_IMAGE from './assets/images/ouh/ouh.webp';
import OUH_CARD_A_IMAGE from './assets/images/ouh/ouh_a.webp';
import OUH_CARD_B_IMAGE from './assets/images/ouh/ouh_b.webp';

// goa page
import GOA_MEDIA from './assets/videos/goa.mp4';
import GOA_IMAGE from './assets/images/goa/goa.webp';
import GOA_CARD_A_IMAGE from './assets/images/goa/goa_a.webp';
import GOA_CARD_B_IMAGE from './assets/images/goa/goa_b.webp';

// footer page


// icon
import ICON_CLEAR from './assets/images/icon/icon-clear.png';
import ICON_LEFT from './assets/images/icon/icon-left.png';
import ICON_RIGHT from './assets/images/icon/icon-right.png';
import ICON_LINE from './assets/images/icon/icon-line.png';
import ICON_POINT from './assets/images/icon/icon-point.png';
import ICON_WARNING from './assets/images/icon/icon-warning.png';

const LOADER_DATA = [

    { name: "yf-logo", type: "image", url: YF_LOGO },
    { name: "background-sound", type: "media", url: BACKGROUND_SOUND },

    { name: "limbo-a-media", type: "media", url: LIMBO_A_MEDIA },
    { name: "limbo-b-media", type: "media", url: LIMBO_B_MEDIA },
    // { name: "limbo-image", type: "image", url: LIMBO_IMAGE },
    { name: "cooa-image", type: "image", url: COOA_IMAGE },
    { name: "limbo-text-0", type: "image", url: LIMBO_TEXT_0_IMAGE },
    { name: "limbo-text-1", type: "image", url: LIMBO_TEXT_1_IMAGE },
    { name: "limbo-text-2", type: "image", url: LIMBO_TEXT_2_IMAGE },
    { name: "limbo-text-3", type: "image", url: LIMBO_TEXT_3_IMAGE },
    { name: "limbo-text-4", type: "image", url: LIMBO_TEXT_4_IMAGE },
    { name: "limbo-text-5", type: "image", url: LIMBO_TEXT_5_IMAGE },
    { name: "limbo-text-6", type: "image", url: LIMBO_TEXT_6_IMAGE },
    { name: "limbo-text-7", type: "image", url: LIMBO_TEXT_7_IMAGE },

    { name: "trailer-media", type: "media", url: TRAILER_MEDIA },
    // { name: "trailer-image", type: "image", url: TRAILER_IMAGE },
    { name: "trailer-background", type: "image", url: TRAILER_BACKGROUND },
    { name: "trailer-corner-0", type: "image", url: TRAILER_CORNER_0_IMAGE },
    { name: "trailer-corner-1", type: "image", url: TRAILER_CORNER_1_IMAGE },
    { name: "trailer-corner-2", type: "image", url: TRAILER_CORNER_2_IMAGE },
    { name: "trailer-corner-3", type: "image", url: TRAILER_CORNER_3_IMAGE },
    { name: "trailer-caution", type: "image", url: TRAILER_CAUTION_IMAGE },
    { name: "trailer-text-0", type: "image", url: TRAILER_TEXT_0_IMAGE },
    { name: "trailer-text-1", type: "image", url: TRAILER_TEXT_1_IMAGE },
    { name: "trailer-text-2", type: "image", url: TRAILER_TEXT_2_IMAGE },
    { name: "trailer-text-3", type: "image", url: TRAILER_TEXT_3_IMAGE },
    { name: "trailer-text-4", type: "image", url: TRAILER_TEXT_4_IMAGE },

    { name: "ncs-media", type: "media", url: NCS_MEDIA },
    { name: "ncs-image", type: "image", url: NCS_IMAGE },
    { name: "ncs-a-image", type: "image", url: NCS_CARD_A_IMAGE },
    { name: "ncs-b-image", type: "image", url: NCS_CARD_B_IMAGE },

    { name: "ouh-media", type: "media", url: OUH_MEDIA },
    { name: "ouh-image", type: "image", url: OUH_IMAGE },
    { name: "ouh-a-image", type: "image", url: OUH_CARD_A_IMAGE },
    { name: "ouh-b-image", type: "image", url: OUH_CARD_B_IMAGE },

    { name: "goa-media", type: "media", url: GOA_MEDIA },
    { name: "goa-image", type: "image", url: GOA_IMAGE },
    { name: "goa-a-image", type: "image", url: GOA_CARD_A_IMAGE },
    { name: "goa-b-image", type: "image", url: GOA_CARD_B_IMAGE },

    { name: "icon-clear", type: "image", url: ICON_CLEAR },
    { name: "icon-left", type: "image", url: ICON_LEFT },
    { name: "icon-right", type: "image", url: ICON_RIGHT },
    { name: "icon-line", type: "image", url: ICON_LINE },
    { name: "icon-point", type: "image", url: ICON_POINT },
    { name: "icon-warning", type: "image", url: ICON_WARNING },

]


const CONTAINER_ELEMENT = document.querySelector(".container>div")
const LOADER_ELEMENT = document.querySelector(".loader")
// const LOADER_PROGRESS_ELEMENT = document.querySelector(".loader-progress>span")
const PAGE_ELEMENTS = document.querySelectorAll(".item.item-page")
const PAGE_VIEW_ELEMENTS = document.querySelectorAll(".item.item-page .page-view")
const MENU_ELEMENT = document.querySelector(".menu")
const MENU_START_ELEMENT = document.querySelector(".menu .menu-logo")
const PROGRESS_ELEMENT = document.querySelector(".outside-progress")
const PROGRESS_INSIDE_ELEMENT = document.querySelector(".outside-progress .inside-progress")
const PROGRESS_POINTS_ELEMENT = document.querySelector(".progress .progress-points")
const PROGRESS_BUTTON_ELEMENT = document.querySelector(".progress .progress-button")
const PROGRESS_TITLE_ELEMENT = document.querySelector(".progress .progress-title")
const NCS_CARD_A_ELEMENT = document.querySelector(".ncs-a-image")
const NCS_CARD_B_ELEMENT = document.querySelector(".ncs-b-image")
const OUH_CARD_A_ELEMENT = document.querySelector(".ouh-a-image")
const OUH_CARD_B_ELEMENT = document.querySelector(".ouh-b-image")
const GOA_CARD_A_ELEMENT = document.querySelector(".goa-a-image")
const GOA_CARD_B_ELEMENT = document.querySelector(".goa-b-image")

const COOA_IMAGE_ELEMENT = document.querySelector(".cooa-image")
const MELTDOWN_IMAGE_ELEMENT = document.querySelector(".meltdown-image")
const COMPANY_INFO_ELEMENT = document.querySelector(".company-info")

const TRAILER_VIDEO_ELEMENT = document.querySelector(".trailer-video>video")
const MUSIC_SWITCH_ELEMENT = document.querySelector(".menu-music")
const BACKGROUND_SOUND_ELEMENT = document.querySelector(".background-sound")

const CURSOR_MOVE_ELEMENT = document.querySelector(".cursor_move")

const TITLES = [
    "世界观", "预告片", "NCS", "OUH", "GOA", ""
]

const PAGE_CARD = [
    {
        el: COOA_IMAGE_ELEMENT,
        page: 0,
        transform: {
            filter: { delay: .0, duration: .5, curves: 1, data: [0, 1], company: "" },
            // translateX: { delay: .0, duration: 0.7, curves: 1, data: [0, 200.0], company: "vw" },
        }
    },
    {
        el: MELTDOWN_IMAGE_ELEMENT,
        page: 0,
        transform: {
            filter: { delay: .0, duration: .5, curves: 1, data: [0, 1], company: "" },
            // translateX: { delay: .0, duration: 0.7, curves: 1, data: [0, 200.0], company: "vw" },
        }
    },  
    {
        el: COMPANY_INFO_ELEMENT,
        page: 0,
        transform: {
            color: { delay: .0, duration: .5, curves: 1, data: [0, 1], company: "" },
            // translateX: { delay: .0, duration: 0.7, curves: 1, data: [0, 200.0], company: "vw" },
        }
    },
    // {
    //     el: document.querySelector(".limbo-text-4"),
    //     page: 0,
    //     transform: {
    //         scale: { delay: .7, duration: 0.3, curves: 1, data: [0.5, 1.0], company: "" },
    //     }
    // },
    // {
    //     el: document.querySelector(".limbo-text-5"),
    //     page: 0,
    //     transform: {
    //         scale: { delay: .7, duration: .3, curves: 1, data: [1.0, 2.0], company: "" },
    //     }
    // },
    {
        el: NCS_CARD_A_ELEMENT,
        page: 2,
        transform: {
            translateX: { delay: .6, duration: .4, curves: 1, data: [50, 0], company: "vh" },
        }
    },
    {
        el: NCS_CARD_B_ELEMENT,
        page: 2,
        transform: {
            translateX: { delay: .6, duration: .4, curves: 1, data: [150, 0], company: "vh" },
        }
    },
    {
        el: OUH_CARD_A_ELEMENT,
        page: 3,
        transform: {
            translateX: { delay: .6, duration: .4, curves: 1, data: [50, 0], company: "vh" },
        }
    },
    {
        el: OUH_CARD_B_ELEMENT,
        page: 3,
        transform: {
            translateX: { delay: .6, duration: .4, curves: 1, data: [150, 0], company: "vh" },
        }
    },
    {
        el: GOA_CARD_A_ELEMENT,
        page: 4,
        transform: {
            translateX: { delay: .6, duration: .4, curves: 1, data: [50, 0], company: "vh" },
        }
    },
    {
        el: GOA_CARD_B_ELEMENT,
        page: 4,
        transform: {
            translateX: { delay: .6, duration: .4, curves: 1, data: [150, 0], company: "vh" },
        }
    },
]

const LimboTexture1 = new THREE.VideoTexture(document.querySelector(".limbo-a-media"));
const LimboTexture2 = new THREE.VideoTexture(document.querySelector(".limbo-b-media"));
const TrailerTexture = new THREE.VideoTexture(document.querySelector(".trailer-media"));
const NCSTexture = new THREE.VideoTexture(document.querySelector(".ncs-media"));
const OUHTexture = new THREE.VideoTexture(document.querySelector(".ouh-media"));
const GOATexture = new THREE.VideoTexture(document.querySelector(".goa-media"));

const YF_CONFIG = {
    runtime: {
        fps: 60,
    },
    fixedRuntime: {
        fps: 24,
    },
    rollingAxis: {

        speed: 1.0,

        interpolation: .05,

        shaftLength: 30000

    },
    state: {

        canvas: document.querySelector(".out-canvas"),
        deviceWidth: window.innerWidth,
        deviceHeight: window.innerHeight,
        devicePixelRatio: window.devicePixelRatio,

        passes: [
            {
                uuid: "limbo",
                type: 2,
                params: {
                    texture1: LimboTexture1,
                    texture2: LimboTexture2,
                },
                animations: [
                    {
                        delay: .0,
                        duration: .5,
                        curves: 1,
                        data: {
                            "u_mix": [0, 1],
                        }
                    },
                ]

            },
            {
                uuid: "trailer",
                type: 0,
                params: {
                    texture: TrailerTexture,
                },
                animations: [
                    {
                        delay: .0,
                        duration: 1,
                        curves: 1,
                        data: {}
                    },
                ]

            },
            {
                uuid: "ncs",
                type: 1,
                params: {
                    texture: NCSTexture,
                },

                animations: [

                    {
                        delay: .0,
                        duration: .4,
                        curves: 1,
                        data: {
                            "position": [new THREE.Vector3(-5, .5, -6), new THREE.Vector3(-0, .5, -6)],
                            "u_scale": [new THREE.Vector2(-1, -1), new THREE.Vector2(0.5, 0.5)],
                        }
                    },

                    {

                        delay: .4,
                        duration: .3,
                        curves: 1,
                        data: {
                            "position": [new THREE.Vector3(-0, .5, -6), new THREE.Vector3(0, 0, 0)],
                            "u_scale": [new THREE.Vector2(0.5, 0.5), new THREE.Vector2(1.0, 1.0)],
                        }
                    },

                ]
            },
            {
                uuid: "ouh",
                type: 1,
                params: {
                    texture: OUHTexture,
                },

                animations: [
                    {
                        delay: .0,
                        duration: .4,
                        curves: 1,
                        data: {
                            "position": [new THREE.Vector3(-5, .5, -6), new THREE.Vector3(-0, .5, -6)],
                            "u_scale": [new THREE.Vector2(-1, -1), new THREE.Vector2(0.5, 0.5)],
                        }
                    },

                    {

                        delay: .4,
                        duration: .3,
                        curves: 1,
                        data: {
                            "position": [new THREE.Vector3(-0, .5, -6), new THREE.Vector3(0, 0, 0)],
                            "u_scale": [new THREE.Vector2(0.5, 0.5), new THREE.Vector2(1.0, 1.0)],
                        }
                    },

                ]
            },
            {
                uuid: "goa",
                type: 1,
                params: {
                    texture: GOATexture,
                },

                animations: [
                    {
                        delay: .0,
                        duration: .4,
                        curves: 1,
                        data: {
                            "position": [new THREE.Vector3(-5, .5, -6), new THREE.Vector3(-0, .5, -6)],
                            "u_scale": [new THREE.Vector2(-1, -1), new THREE.Vector2(0.5, 0.5)],
                        }
                    },

                    {

                        delay: .4,
                        duration: .3,
                        curves: 1,
                        data: {
                            "position": [new THREE.Vector3(-0, .5, -6), new THREE.Vector3(0, 0, 0)],
                            "u_scale": [new THREE.Vector2(0.5, 0.5), new THREE.Vector2(1.0, 1.0)],
                        }
                    },

                ]

            },
            {
                uuid: "footer",
                type: 0,
                params: {
                    texture: LimboTexture1,
                },
                animations: [
                    {
                        delay: .0,
                        duration: 1.0,
                        curves: 1,
                        data: {}
                    }
                ]
            }
        ],
    },
}

const _LOADER = new Loader()
const yf = new YF(YF_CONFIG)

const beforeMouseData = new THREE.Vector2(-100, -100)
const currentMouseData = new THREE.Vector2(-100, -100)

_LOADER.load(LOADER_DATA, function (progress) {

    // LOADER_PROGRESS_ELEMENT.innerText = progress.toFixed(0) + "%"

})
    .then(res => {

        res.forEach(item => changeElement(item.name, item.url))

        PROGRESS_TITLE_ELEMENT.innerHTML = ""

        for (let i = 0; i < PAGE_ELEMENTS.length; i++) {

            const transAs = ((PAGE_ELEMENTS[i].offsetLeft + PAGE_ELEMENTS[i].offsetWidth) / (CONTAINER_ELEMENT.clientWidth - window.innerWidth)) * PROGRESS_ELEMENT.clientHeight

            PROGRESS_POINTS_ELEMENT.innerHTML += `<div class="point-item" style="transform:translateY(${transAs}px);"></div>`

        }

        for (let i = 0; i < TITLES.length; i++) {

            let preWidth = 0

            if (i !== 0) {

                preWidth = (PAGE_ELEMENTS[i - 1].offsetLeft + PAGE_ELEMENTS[i - 1].offsetWidth)

            }

            const transAs = (((PAGE_ELEMENTS[i].offsetLeft + PAGE_ELEMENTS[i].offsetWidth - preWidth) * 0.5 + preWidth) / (CONTAINER_ELEMENT.clientWidth - window.innerWidth)) * PROGRESS_ELEMENT.clientHeight

            PROGRESS_TITLE_ELEMENT.innerHTML += `<div class="progress-title-item" style="transform:translate( 0.4vh,${transAs}px) rotateZ(90deg);">${TITLES[i]}</div>`

        }

        MENU_ELEMENT.style.transform = "translateX(0)"

        for (let i = 0; i < PAGE_ELEMENTS.length; i++) {
            translateCard(i, 1)
        }

        LOADER_ELEMENT.style.display = "none"

        yf.start()

    })


window.addEventListener("resize", resize)
window.addEventListener("DOMMouseScroll", mousewheel)
window.addEventListener("mousewheel", mousewheel)
window.addEventListener("pointermove", mousemove)
window.addEventListener("touchstart", touchstart)
window.addEventListener("touchmove", touchmove)
window.addEventListener("touchend", touchend)

MENU_START_ELEMENT.addEventListener("click", (e) => {

    yf.rollingAxis.setRatio(0)

})


function switchMusicState(state) {

    if (state) {

        BACKGROUND_SOUND_ELEMENT.volume = 0.1
        BACKGROUND_SOUND_ELEMENT.muted = false
        BACKGROUND_SOUND_ELEMENT.play()

        MUSIC_SWITCH_ELEMENT.classList.add("music-opened")

    } else {

        BACKGROUND_SOUND_ELEMENT.volume = 0
        BACKGROUND_SOUND_ELEMENT.muted = true
        BACKGROUND_SOUND_ELEMENT.pause()

        MUSIC_SWITCH_ELEMENT.classList.remove("music-opened")

    }

}

MUSIC_SWITCH_ELEMENT.addEventListener("click", (e) => {

    switchMusicState(!MUSIC_SWITCH_ELEMENT.classList.value.includes("music-opened"))

})

let trailerPlaying = false

function isInScene(dom) {

    const pos = dom.getBoundingClientRect()

    if ((pos.left + pos.width) < 0 || pos.left > window.innerWidth) {

        return false

    }

    return true

}

TRAILER_VIDEO_ELEMENT.addEventListener("play", (e) => {
    switchMusicState(false)
    trailerPlaying = true
})

TRAILER_VIDEO_ELEMENT.addEventListener("pause", (e) => {
    trailerPlaying = false
})

PROGRESS_BUTTON_ELEMENT.addEventListener("click", (e) => {

    yf.rollingAxis.setRatio(e.offsetY / e.target.clientHeight)

})

PROGRESS_TITLE_ELEMENT.addEventListener("click", (e) => {

    const index = TITLES.indexOf(e.target.innerText)

    if (index > -1) {

        yf.rollingAxis.setRatio((PAGE_ELEMENTS[index].offsetLeft + PAGE_VIEW_ELEMENTS[index].offsetLeft) / (CONTAINER_ELEMENT.clientWidth - window.innerWidth))

        // if (index === 0) {

        //     yf.rollingAxis.setRatio(0.5)

        // } else {

        //     const transAs = (PAGE_ELEMENTS[index - 1].offsetLeft + PAGE_ELEMENTS[index - 1].offsetWidth) / CONTAINER_ELEMENT.clientWidth

        //     yf.rollingAxis.setRatio(transAs)

        // }

    }

})

yf.addEventListener("FixedRuntime", function (state) {

    const ratio = yf.rollingAxis.getRatio()
    const transAs = (CONTAINER_ELEMENT.clientWidth - window.innerWidth) * ratio + window.innerWidth


    let index = 0
    let move = 0

    for (let i = 0; i < PAGE_ELEMENTS.length; i++) {

        const pr = !i ? window.innerWidth : 0
        const page = PAGE_ELEMENTS[i]

        if (
            transAs <= (page.offsetLeft + page.offsetWidth)
        ) {

            index = i

            move = ((transAs - page.offsetLeft - pr) / (page.offsetWidth - pr)) % 1

            break

        }

    }

    state.time = move

    if (yf.stage.setCurrentPassIndex(index)) {

        for (let i = 0; i < PROGRESS_TITLE_ELEMENT.childNodes.length; i++) {

            const title = PROGRESS_TITLE_ELEMENT.childNodes[i]

            title.classList.remove("title-select")

        }

        PROGRESS_TITLE_ELEMENT.childNodes[index].classList.add("title-select")

    }

    yf.stage.render(state)

})

yf.addEventListener("Runtime", function (state) {

    yf.rollingAxis.update(state)

    const ratio = yf.rollingAxis.getRatio()
    const transAs = (CONTAINER_ELEMENT.clientWidth - window.innerWidth) * ratio + window.innerWidth

    let index = 0
    let move = 0

    for (let i = 0; i < PAGE_ELEMENTS.length; i++) {

        const pr = !i ? window.innerWidth : 0
        const page = PAGE_ELEMENTS[i]

        if (
            transAs <= (page.offsetLeft + page.offsetWidth)
        ) {

            index = i

            move = ((transAs - page.offsetLeft - pr) / (page.offsetWidth - pr)) % 1

            break

        }

    }

    if (index === 0) {

        COOA_IMAGE_ELEMENT.style.transform = `translateX(${transAs - window.innerWidth}px)`
        MELTDOWN_IMAGE_ELEMENT.style.transform = `translateX(${transAs - window.innerWidth}px)`
        COMPANY_INFO_ELEMENT.style.transform = `translateX(${transAs - window.innerWidth}px)`

        // Object.assign(COOA_IMAGE_ELEMENT.style,{
        //     transform:`translateX(${transAs - window.innerWidth}px`
        // })

        // Object.assign(MELTDOWN_IMAGE_ELEMENT.style,{
        //     transform:`translateX(${transAs - window.innerWidth}px`
        // })

    }

    translateCard(index, move)

    if (!isInScene(TRAILER_VIDEO_ELEMENT) && trailerPlaying) {

        TRAILER_VIDEO_ELEMENT.pause()

    }

    if (PROGRESS_INSIDE_ELEMENT) {
        // PROGRESS_INSIDE_ELEMENT.style.transform = `scaleY(${Math.min(Math.max((transAs- window.innerWidth) / (CONTAINER_ELEMENT.clientWidth ), 0), 1)})`
        PROGRESS_INSIDE_ELEMENT.style.transform = `scaleY(${Math.min(Math.max(ratio, 0), 1)})`
    }

    CONTAINER_ELEMENT.style.transform = `translateX(${-transAs + window.innerWidth}px)`


    currentMouseData.lerp(beforeMouseData, .1)

    CURSOR_MOVE_ELEMENT.style.transform = `translate(${currentMouseData.x}px,${currentMouseData.y}px)`

})

function translateCard(index, ratio) {

    const cards = PAGE_CARD.filter(item => item.page === index)

    for (let i in cards) {

        const card = cards[i]

        if (card.el) {

            let transform = ""

            if (card.transform) {

                let trans3d = ""
                let filter = ""
                let opacity = ""
                let color = ""

                for (let key in card.transform) {

                    const anima = card.transform[key]

                    const start = anima.delay
                    const end = anima.delay + anima.duration
                    let _ratio = start

                    if (ratio >= start && ratio <= end) {

                        _ratio = ratio

                    } else if (ratio < start) {

                        _ratio = start

                    } else if (ratio > end) {

                        _ratio = end

                    }

                    const rt = (_ratio - start) / anima.duration

                    const ct = (anima.data[1] - anima.data[0]) * rt * anima.curves + anima.data[0]

                    switch (key) {

                        case "filter":
                            filter = `invert(${ct}${anima.company}) `
                            break;
                        case "translateX":
                            trans3d += `translateX(${ct}${anima.company}) `
                            break;
                        case "translateY":
                            trans3d += `translateY(${ct}${anima.company}) `
                            break;
                        case "translateZ":
                            trans3d += `translateZ(${ct}${anima.company}) `
                            break;
                        case "scale":
                            trans3d += `scale(${ct}${anima.company}) `
                            break;
                        case "scaleX":
                            trans3d += `scaleX(${ct}${anima.company}) `
                            break;
                        case "scaleY":
                            trans3d += `scaleY(${ct}${anima.company}) `
                            break;
                        case "rotate":
                            trans3d += `rotate(${ct}${anima.company}) `
                            break;
                        case "rotateX":
                            trans3d += `rotateX(${ct}${anima.company}) `
                            break;
                        case "rotateY":
                            trans3d += `rotateY(${ct}${anima.company}) `
                            break;
                        case "rotateZ":
                            trans3d += `rotateZ(${ct}${anima.company}) `
                            break;
                        case "color":
                            let c = Math.floor(ct * 255);
                            color = `rgb(${c},${c},${c})`

                    }

                }

                // transform = filter + opacity + (trans3d ? ("transform:" + trans3d + ";") : "")

                if (color)
                {
                    card.el.style.color = color;
                }

                if (trans3d) {

                    card.el.style.transform = trans3d

                }

                if (filter) {

                    card.el.style.filter = filter

                }

                if (opacity) {

                    card.el.style.opacity = opacity

                }

                // Object.assign(card.el.style,{
                //     ...(transform?{transform: "transform:" + trans3d + ";"}:{}),
                //     ...(opacity?{opacity: opacity}:{}),
                //     ...(filter?{filter: filter}:{}),
                // })

            }


        }


    }

}


function mousemove(e) {

    e.stopPropagation()
    e.preventDefault()

    yf.mousemove(e)

    beforeMouseData.set(e.pageX - 3, e.pageY - 6)

}

function touchstart(event) { }
function touchmove(event) { }
function touchend(event) { }



function resize(e) {

    e.stopPropagation()
    e.preventDefault()

    yf.resize(e)

    for (let i = 0; i < PAGE_ELEMENTS.length - 1; i++) {

        const transAs = ((PAGE_ELEMENTS[i].offsetLeft + PAGE_ELEMENTS[i].offsetWidth) / (CONTAINER_ELEMENT.clientWidth - window.innerWidth)) * PROGRESS_ELEMENT.clientHeight

        PROGRESS_POINTS_ELEMENT.childNodes[i].style.transform = `translateY(${transAs}px)`

    }

    for (let i = 0; i < TITLES.length; i++) {

        let preWidth = 0

        if (i !== 0) {

            preWidth = (PAGE_ELEMENTS[i - 1].offsetLeft + PAGE_ELEMENTS[i - 1].offsetWidth)

        }

        const transAs = (((PAGE_ELEMENTS[i].offsetLeft + PAGE_ELEMENTS[i].offsetWidth - preWidth) * 0.5 + preWidth) / (CONTAINER_ELEMENT.clientWidth - window.innerWidth)) * PROGRESS_ELEMENT.clientHeight

        PROGRESS_TITLE_ELEMENT.childNodes[i].style.transform = `translate( 0.4vh,${transAs}px) rotateZ(90deg)`

    }

}

// let lastTime = 0

function mousewheel(e) {

    e.stopPropagation()

    // let fps = parseInt((1000 / (e.timeStamp - lastTime)))

    // fps = Math.min(Math.max(fps, 4), 240)

    // let direction = 1

    // if(!isNaN(fps)){

    //     if (e.type === "mousewheel") {

    //         if( e.deltaY < 0 ){
    //             direction = -1
    //         }


    //     } else if (e.type === "DOMMouseScroll") {

    //         if( e.detail < 0 ){
    //             direction = -1
    //         }

    //     }

    //     yf.rollingAxis.changeRatio((4 * fps * direction ) / CONTAINER_ELEMENT.clientWidth)

    // }


    if (e.type === "mousewheel") {

        yf.rollingAxis.changeRatio((e.deltaY) / CONTAINER_ELEMENT.clientWidth)

    } else if (e.type === "DOMMouseScroll") {

        yf.rollingAxis.changeRatio((e.detail * 60) / CONTAINER_ELEMENT.clientWidth)

    }

}


function changeElement(name, url) {

    const elements = document.querySelectorAll("." + name)

    for (let i = 0; i < elements.length; i++) {

        const ele = elements[i]

        ele.src = url

    }


}

window.addEventListener("keydown", function (e) {
    e.stopPropagation(); e.preventDefault()
})