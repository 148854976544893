 import * as THREE from 'three'


import HotPixelVertex from '../glsl/HotPixel.vs';
import HotPixelFragment from '../glsl/HotPixel.fs';

export const HotPixelShader = {

    uniforms: {

        'tDiffuse': new THREE.Uniform(null),

        'time': new THREE.Uniform(0),

    },

    vertexShader: HotPixelVertex,

    fragmentShader: HotPixelFragment,

    update() {

        // console.log(this)

    }

}