import { YFModule } from './YFModule';
import { Runtime } from './Runtime';

export class FixedRuntime extends Runtime {

    constructor(context, config) {

        super(context, config)

        this.fps = this._config.fps

        this.delay = 1000 / this.fps

        this.frame = -1
    }


    loop(t) {

        let seg = Math.floor(t / this.delay)

        if (seg > this.frame) {

            this.delta = t - this.time

            this.time = t

            this.frame = seg

            this.dispatch("FixedRuntime", {
                time: this.time,
                delta: this.delta
            })

        }

        this.tref = requestAnimationFrame((t) => this.loop(performance.now()))

    }

    frameRate(fps) {

        this.fps = fps

        this.delay = 1000 / this.fps

        this.frame = -1

        this.time = 0

        this.delta = 0

    }

}