

import { Event } from './Event.js';

import { Runtime, FixedRuntime, Stage, RollingAxis, View, Menu } from './YFModule/index.js';

export class YF {

    constructor(config) {

        this.event = new Event()

        this.runtime = new Runtime(this, config.runtime)

        this.fixedRuntime = new FixedRuntime(this, config.fixedRuntime)

        this.rollingAxis = new RollingAxis(this, config.rollingAxis)

        this.stage = new Stage(this, config.state)

        this.awake()

    }

    awake() {

        this.event.dispatch("Awake")

    }

    start() {

        this.event.dispatch("Start")

        this.runtime.start()
        this.fixedRuntime.start()

    }

    pause() {

        this.event.dispatch("Pause")
        this.runtime.pause()
        this.fixedRuntime.pause()

    }

    clear() {

        this.event.dispatch("Destroyed")

    }

    resize(e) {

        this.stage.resize(e)

    }

    mousemove(e) {

        this.stage.mousemove(e)

    }

    addEventListener(a, b) {
        this.event.addEventListener(a, b)

    }

    removeEventListener(a, b) {
        this.event.removeEventListener(a, b)

    }

    dispatch(a, b) {
        this.event.dispatch(a, b)
    }

}

//