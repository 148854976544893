export class YFModule {

    constructor(context, config) {

        this._context = context

        this._config = config

        this.addEventListener("Awake", this.onAwake.bind(this))
        this.addEventListener("Start", this.onStart.bind(this))
        this.addEventListener("Pause", this.onPause.bind(this))
        this.addEventListener("Destroy", this.onDestroyed.bind(this))

        this.addEventListener("NativeLoaded", this.onNativeLoaded.bind(this))
        this.addEventListener("NativeResize", this.onNativeResize.bind(this))
        this.addEventListener("NativeMouseWheel", this.onNativeMouseWheel.bind(this))
        this.addEventListener("NativeMouseMove", this.onNativeMouseMove.bind(this))
        this.addEventListener("Loaded", this.onLoaded.bind(this))
        this.addEventListener("MenuSeek", this.onMenuSeek.bind(this))
        this.addEventListener("Rolling", this.onRolling.bind(this))
        this.addEventListener("Runtime", this.onRuntime.bind(this))
        this.addEventListener("FixedRuntime", this.onFixedRuntime.bind(this))
        this.addEventListener("ViewPageChange", this.onViewPageChange.bind(this))

    }

    addEventListener(a, b) {

        this._context.addEventListener(a, b)

    }

    removeEventListener(a, b) {

        this._context.removeEventListener(a, b)

    }

    dispatch(a, b) {

        this._context.dispatch(a, b)

    }

    onAwake(n) {}
    onStart() {}
    onPause() {}
    onDestroyed() {}

    // Native
    onNativeLoaded() {}
    onNativeResize() {}
    onNativeMouseWheel() {}
    onNativeMouseMove() {}

    // loader
    onLoaded() {}

    // menu
    onMenuSeek() {}

    // rollingAxis
    onRolling() {}

    // runtime
    onRuntime() {}

    // fixedRuntime
    onFixedRuntime() {}

    // stage

    // view
    onViewPageChange() {}



}