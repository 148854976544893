import { Event } from '../Event'

import * as THREE from 'three';

import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

// import {
//     ShaderPass,
// } from "postprocessing";

class Animation {

    constructor(config) {

        this._enable = true

        this.init(config)

    }

    init(config) {
        this.start = config.delay
        this.end = config.delay + config.duration
        this.duration = config.duration

        this.curves = config.curves

        this.data = config.data

        this.current = {}

        this.enabled = false

    }

    setState(value) {
        this.enabled = value
    }

    getState() {
        return this.enabled
    }

    getData() {

        return this.current

    }

    seek(ratio) {

        const data = {}

        const rt = (ratio - this.start) / this.duration

        for (let key in this.data) {

            let left = this.data[key][0], right = this.data[key][1]

            switch (left.constructor) {

                case THREE.Vector2:
                case THREE.Vector3:
                case THREE.Vector4:

                    data[key] = right.clone().sub(left).multiplyScalar(rt * this.curves).add(left)

                    break;
                case Number:

                    data[key] = (right - left) * rt * this.curves + left

                    break;
                case Boolean:
                case String:

                    data[key] = rt > 0.5 ? right : left

                    break;

            }

        }

        this.current = data

    }

}

export class ContextPass {

    constructor(config = {}) {

        this._events = new Event()

        this._enabled = false

        this.uuid = config.uuid || THREE.MathUtils.generateUUID()

        this._config = config

        // this._pass = config.pass ? new ShaderPass(new THREE.ShaderMaterial(config.pass)) : null
        // this._pass = config.pass ? new ShaderPass(config.pass) : null

        this.animations = []

        this.startTime = 0

        this.endTime = 0

        this.duration = 0

        if (Array.isArray(config.animations)) {

            this.startTime = config.animations[0].delay

            this.endTime = config.animations[0].delay + config.animations[0].duration

            config.animations.forEach(anima => {

                this.startTime = Math.min(this.startTime, anima.delay)

                this.endTime = Math.max(this.endTime, anima.delay + anima.duration)

                this.animations.push(new Animation(anima))

            })

            this.duration = this.endTime - this.startTime

        }

        for (let i in this.animations) {

            const anima = this.animations[i]

            if (anima.start < this.startTime) {
                this.startTime = anima.start
            }

            if (anima.end > this.endTime) {
                this.endTime = anima.end
            }

        }


    }

    isInspectRatio(ratio) {

        if (ratio >= this.startTime && ratio < this.endTime) {

            return true

        }

        return false

    }

    start() { }
    pause() { }

    update(ratio) {

        if (!this.getState()) { return }

        this.animations.forEach(anima => {

            if (ratio >= anima.start && ratio <= anima.end) {

                anima.seek(ratio)

                anima.setState(true)

                this.setData(anima.getData())

            } else {

                if (anima.getState()) {

                    if (ratio < anima.start) {

                        anima.seek(anima.start)

                    } else if (ratio > anima.end) {

                        anima.seek(anima.end)

                    }

                    this.setData(anima.getData())

                }

                anima.setState(false)

            }


            // if (ratio >= anima.start && ratio <= anima.end) {

            //     anima.seek(ratio)

            //     anima.setState(true)

            //     this.setData(anima.getData())

            // } else if(ratio < anima.start){

            //     anima.seek(anima.start)

            //     this.setData(anima.getData())

            //     anima.setState(true)

            // } else if(ratio > anima.end){
            //     anima.seek(anima.end)

            //     this.setData(anima.getData())

            //     anima.setState(true)

            // }


        })

    }

    dispatch(eventName, params) {

        this._events.dispatch(eventName, params)

    }

    addEventListener(eventName, func) {

        this._events.addEventListener(eventName, func)

    }

    removeEventListener(eventName, func) {

        this._events.removeEventListener(eventName, func)

    }

    // getPass() {

    //     return this._pass

    // }

    // setPass(pass) {

    //     this._pass = pass

    // }

    setPassUniformValue(key, value) {

        if (this.material.uniforms[key]) {

            this.material.uniforms[key] = new THREE.Uniform(value)

        }

        this.material.needsUpdate = true

    }

    getPassUniformValue(key) {

        return this.material.uniforms[key]

    }

    setData(data) {

        for (let key in data) {

            this.setPassUniformValue(key, data[key])

        }

    }

    setState(state) {

        if (this._enabled === state) {

            return false

        } else {

            this._enabled = state

        }

        return true

    }

    getState() {

        return this._enabled

    }

    start() {

        // this._pass.enabled = true

    }

    pause() {

        // this._pass.enabled = false

    }

    load() {

    }

    resize() { }

    mousemove() { }

    render() { }

}