import { YFModule } from './YFModule';

export class Runtime extends YFModule {

    constructor(context, config) {

        super(
            context,
            Object.assign({}, config || {})
        )

        this.tref = 0

        this.isPlaying = false

        this.time = 0

        this.delta = 0

        this.fps = this._config.fps

        this.delay = 1000 / this.fps

        this.frame = -1

    }

    getDelta() {

        return this.delta

    }

    loop(t) {

        let seg = Math.floor(t / this.delay)

        if (seg > this.frame) {

            this.delta = t - this.time

            this.time = t

            this.frame = seg

            this.dispatch("Runtime", {
                time: this.time,
                delta: this.delta
            })

        }

        this.tref = requestAnimationFrame((t) => this.loop(performance.now()))

    }

    start() {

        if (!this.isPlaying) {

            this.isPlaying = true

            this.time = performance.now()

            this.tref = requestAnimationFrame((t) => this.loop(performance.now()))

        }

    }

    pause() {

        if (this.isPlaying) {

            cancelAnimationFrame(this.tref)

            this.time = 0

            this.isPlaying = false

        }

    }

}