 import * as THREE from 'three'

import TransitionVertex from '../glsl/TransitionShader.vs';
import TransitionFragment from '../glsl/TransitionShader.fs';

export const TransitionShader = {

    uniforms: {

        "disp": new THREE.Uniform(null),
        "texture1": new THREE.Uniform(null),
        "texture2": new THREE.Uniform(null),

        "dispFactor": new THREE.Uniform(0),
        "angle2": new THREE.Uniform((Math.PI / 180.0) * -45.0),
        "angle1": new THREE.Uniform((Math.PI / 180.0) * -45.0),
        "intensity1": new THREE.Uniform(0.1),
        "intensity2": new THREE.Uniform(0.1),

        "res": new THREE.Uniform(
            new THREE.Vector4(
                0.0,
                0.0,
                0.0,
                0.0
            )
        )

    },

    vertexShader: TransitionVertex,

    fragmentShader: TransitionFragment

}