export class Event {

    constructor() {

        this._events = {}

    }

    addEventListener(eventName, func) {

        if (!Array.isArray(this._events[eventName])) {

            this._events[eventName] = []

        }

        this._events[eventName].push(func)

    }

    removeEventListener(eventName, func) {

        if (!Array.isArray(this._events[eventName])) {

            return false

        }

        const index = this._events[eventName].indexOf(func)

        if (index >= 0) {

            this._events[eventName].splice(index, 1)

        }

    }

    dispatch(eventName, params) {

        const event = this._events[eventName]

        if (Array.isArray(event)) {

            for (let i in event) {

                if (event[i] instanceof Function) {

                    event[i](params)

                }

            }

        }

    }

    clear() {

        this._events = {}

    }

}