
import * as THREE from 'three';

import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

// import {
//     ShaderPass,
// } from "postprocessing";

import { ContextPass } from './ContextPass'

export class MixContextPass extends ContextPass {

    constructor(config) {

        super(config)

        this.material = new THREE.ShaderMaterial({

            uniforms: {
                "u_mix": new THREE.Uniform(0.0),
                "u_map_1": new THREE.Uniform(null),
                "u_map_2": new THREE.Uniform(null),
                "u_scale": new THREE.Uniform(new THREE.Vector2(.0, .0)),
                "u_position": new THREE.Uniform(new THREE.Vector2(.0, .0)),
            },
        
            vertexShader: `
            precision lowp float;
            precision lowp int;
            
            varying vec2 vUv;
            void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
            `,
        
            fragmentShader: `
            precision lowp float;
            precision lowp int;
            
            uniform float u_mix;
            uniform sampler2D u_map_1;
            uniform sampler2D u_map_2;
            uniform vec2 u_scale;
            uniform vec2 u_position;
            
            varying vec2 vUv;
            
            vec2 getUv(vec2 uv){
            
                return uv * u_scale - u_position;
        
            }
            
            void main() {
        
                // vec2 uv = getUv( vUv );
            
                vec4 texture1 = texture2D(u_map_1, vUv);
        
                vec4 texture2 = texture2D(u_map_2, vUv);
                
                gl_FragColor = mix(texture1, texture2, u_mix);
            
            }
            `
        })
        // this._pass = new ShaderPass(mixPass)

        // this._pass.enabled = false

        this.texture1 = config.params.texture1
        this.texture2 = config.params.texture2

    }

    load() {

        this.material.uniforms.u_map_1.value = this.texture1
        this.material.uniforms.u_map_2.value = this.texture2


        this.updateScreenRatio(window.innerWidth, window.innerHeight, this.texture1.image.videoWidth, this.texture1.image.videoHeight)

    }

    updateScreenRatio(w, h, w2, h2) {

        let ratio = w / h
        let ratio2 = w2 / h2

        if (!ratio2) return

        let scale = new THREE.Vector2(ratio / ratio2, 1.0)

        let position = new THREE.Vector2((scale.x - 1.0) * 0.5, 0.0)

        if (ratio > ratio2) {

            scale = new THREE.Vector2(scale.y, 1.0 / scale.x);

            position = new THREE.Vector2(0.0, (scale.y - 1.0) * 0.5);

        }

        this.setPassUniformValue("u_scale", scale)
        this.setPassUniformValue("u_position", position)

    }

    start(state) {

        return Promise.all([
            this.texture1.image.play(),
            this.texture2.image.play()
        ])

    }

    pause() {

        this.texture1.image.pause()

        this.texture2.image.pause()

    }

    resize({ w, h }) {
        this.updateScreenRatio(w, h, this.texture1.image.videoWidth, this.texture1.image.videoHeight)
    }

}
