import { YFModule } from './YFModule';

export class RollingAxis extends YFModule {

    constructor(context, config) {

        super(
            context,
            Object.assign({
                speed: 1,
                interpolation: 0.1,
            }, config || {})
        )

        this.ratio = 0

        this._ratio = 0

        this.isWheel = false

        this._timer = null

        this.left = -0.1
        this.right = 1.1

        this.minStep = 0.0005
        this.maxStep = 0.05

    }

    changeRatio(rt) {

        const step = Math.min(Math.max(Math.abs(rt), this.minStep), this.maxStep) * (rt > 0 ? 1 : -1)
        // const step = rt

        const ct = this._ratio + step * this._config.speed

        this.setRatio(ct)

    }

    setRatio(ratio) {

        this._ratio = Math.min(Math.max(ratio, 0.0), 1.0)


        // if (ratio >= this.right) {

        //     this.ratio = this.left
        //     this._ratio = 0

        // } else if (ratio <= this.left) {

        //     this.ratio = this.right
        //     this._ratio = 1.0

        // } else {

        //     this._ratio = ratio

        // }

    }

    update(state) {

        this.ratio = this._lerp(this.ratio, this._ratio, this._config.interpolation)

        // * (state.delta / 16.67)
    }

    getRatio() {

        return this.ratio

    }

    _lerp(c, v, t) {

        if (Math.abs(v - c) < 0.00001) {

            return v
        }

        return c + (v - c) * t

    }

}