import {
	Vector3
} from 'three';

/**
 * Color correction
 */

export const ColorCorrectionShader = {

	uniforms: {

		'tDiffuse': { value: null },
		'powRGB': { value: new Vector3(2, 2, 2) },
		'mulRGB': { value: new Vector3(1, 1, 1) },
		'addRGB': { value: new Vector3(0, 0, 0) }

	},

	vertexShader: /* glsl */`

		varying vec2 vUv;

		void main() {

			vUv = uv;

			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

		}`,

	fragmentShader: /* glsl */`

		uniform sampler2D tDiffuse;
		uniform vec3 powRGB;
		uniform vec3 mulRGB;
		uniform vec3 addRGB;

		varying vec2 vUv;

		void main() {

			vec4 color = texture2D( tDiffuse, vUv );
			gl_FragColor = vec4(mulRGB * pow( ( color.rgb + addRGB ), powRGB ),1.0);

		}`

};
