import * as THREE from 'three'

import { ContextPass } from './ContextPass'

import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

// import {
//     ShaderPass,
// } from "postprocessing";




export class CardContextPass extends ContextPass {

    constructor(config) {

        super(config)

        this.material = new THREE.ShaderMaterial({

            uniforms: {
                "u_map": new THREE.Uniform(null),
                "u_resolution": new THREE.Uniform(new THREE.Vector2(0, 0)),
                "u_deviceRatio": new THREE.Uniform(0),
                "u_color": new THREE.Uniform(new THREE.Vector3(.2, .2, .2)),
                // "u_cardScale": new THREE.Uniform(new THREE.Vector2(.0, .0)), // lbrt
                // "u_cardPosition": new THREE.Uniform(new THREE.Vector2(.0, .0)),
                "u_scale": new THREE.Uniform(new THREE.Vector2(1.0, 1.0)),
                "u_position": new THREE.Uniform(new THREE.Vector2(.0, .0)),
            },

            vertexShader: `
            precision lowp float;
            precision lowp int;
            
            varying vec2 vUv;
            void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
            `,

            fragmentShader: `
            precision lowp float;
            precision lowp int;
            
            uniform sampler2D u_map;
            uniform vec2 u_resolution;
            uniform float u_deviceRatio;
            
            uniform vec3 u_color;
            // uniform vec2 u_cardScale;
            // uniform vec2 u_cardPosition;
            
            uniform vec2 u_scale;
            uniform vec2 u_position;
            
            varying vec2 vUv;
            
            vec2 getUv(vec2 uv){
            
                return uv * u_scale - u_position;
        
            }
            
            void main() {
            
            // vec4 texture = texture2D(u_map, getUv(vUv) + vec2(u_cardPosition.x,0.0));
            
            // vec2 st = gl_FragCoord.xy / (u_resolution.xy * u_deviceRatio);
            
            // vec4 ltrb = step(vec4(u_cardPosition,u_cardScale-u_cardPosition), vec4(st.x,1.0-st.y,1.0-st.x,st.y));
            
            // float show = ltrb.x * ltrb.y * ltrb.z * ltrb.w;
            
            // vec3 color = u_color -  vec3(show) *  (u_color - texture.xyz);
            

            vec4 color = texture2D(u_map, getUv(vUv));
            
            gl_FragColor = color;

            }
            `
        })

        this.texture = config.params.texture

    }

    load() {

        this.material.uniforms.u_map.value = this.texture

        this.setPassUniformValue("u_deviceRatio", window.devicePixelRatio)

        // this.updateScreenRatio(window.innerWidth, window.innerHeight, this.texture.image.videoWidth, this.texture.image.videoHeight)

    }

    // updateScreenRatio(w, h, w2, h2) {

    //     let ratio = w / h
    //     let ratio2 = w2 / h2

    //     if (!ratio2) return

    //     let scale = new THREE.Vector2(ratio / ratio2, 1.0)

    //     let position = new THREE.Vector2((scale.x - 1.0) * 0.5, 0.0)

    //     if (ratio > ratio2) {

    //         scale = new THREE.Vector2(scale.y, 1.0 / scale.x);

    //         position = new THREE.Vector2(0.0, (scale.y - 1.0) * 0.5);

    //     }

    //     this.setPassUniformValue("u_resolution", new THREE.Vector2(w, h))
    //     this.setPassUniformValue("u_scale", scale)
    //     this.setPassUniformValue("u_position", position)

    // }


    setData(data) {
        for (let key in data) {

            switch (key) {

                case "position":
                    this.object.position.copy(data[key])

                default:

                    this.setPassUniformValue(key, data[key])

            }

        }

    }

    // updateUniformScale({ x, y }) {

    //     this.setPassUniformValue("u_cardScale",
    //         new THREE.Vector2(1.0 - x, 1.0 - y)
    //     )

    // }

    // updateUniformPosition({ x, y }) {

    //     this.setPassUniformValue("u_cardPosition",
    //         new THREE.Vector2(x, y)
    //     )

    // }

    seeking(ratio) {

    }

    StateChange(state) {


    }

    start() {

        return this.texture.image.play()

    }

    pause() {

        this.texture.image.pause()

    }

    resize({ w, h }) {

        // this.updateScreenRatio(w, h, this.texture.image.videoWidth, this.texture.image.videoHeight)

    }

    mousemove(e) {

        // console.log(e.clientX / window.innerWidth);

    }


}
