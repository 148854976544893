import * as THREE from "three"

import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

// import {
//     ShaderPass,
//     EffectPass
// } from "postprocessing";

import { ContextPass } from './ContextPass'

export class MediaContextPass extends ContextPass {

    constructor(config) {

        super(config)

        // this._pass = new ShaderPass(mediaPass)

        this.material = new THREE.ShaderMaterial({

            uniforms: {
                "u_map": new THREE.Uniform(null),
                "u_scale": new THREE.Uniform(new THREE.Vector2(.0, .0)),
                "u_position": new THREE.Uniform(new THREE.Vector2(.0, .0)),
            },

            vertexShader: `
                precision lowp float;
                precision lowp int;
                
                varying vec2 vUv;
        
                void main() {
        
                    vUv = uv;
                    
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        
                }
            `,

            fragmentShader: `
                precision lowp float;
                precision lowp int;
                
                uniform sampler2D u_map;
                uniform vec2 u_scale;
                uniform vec2 u_position;
                
                varying vec2 vUv;
                
                vec2 getUv(vec2 uv){
                
                    return uv * u_scale - u_position;
        
                }
                
                void main() {
        
                    vec4 texture = texture2D(u_map, vUv);
                
                    gl_FragColor = texture;  
                
                }
            `
        })

        this.texture = config.params.texture

    }

    load() {

        this.material.uniforms.u_map.value = this.texture

        this.updateScreenRatio(window.innerWidth, window.innerHeight, this.texture.image.videoWidth, this.texture.image.videoHeight)

    }

    updateScreenRatio(w, h, w2, h2) {

        let ratio = w / h
        let ratio2 = w2 / h2

        if (!ratio2) return

        let scale = new THREE.Vector2(ratio / ratio2, 1.0)

        let position = new THREE.Vector2((scale.x - 1.0) * 0.5, 0.0)

        if (ratio > ratio2) {

            scale = new THREE.Vector2(scale.y, 1.0 / scale.x);

            position = new THREE.Vector2(0.0, (scale.y - 1.0) * 0.5);

        }

        this.setPassUniformValue("u_scale", scale)
        this.setPassUniformValue("u_position", position)

    }


    start(state) {

        return this.texture.image.play()
        // .then(() => {
        //     this._pass.enabled = true
        // })

    }

    pause() {

        // this._pass.enabled = false

        this.texture.image.pause()

    }

    seeking(ratio) {

    }

    mousemove(e) {
    }

    resize({ w, h }) {

        this.updateScreenRatio(w, h, this.texture.image.videoWidth, this.texture.image.videoHeight)

    }

}
