

import * as THREE from 'three';


const MouseEffectShader = {
    uniforms: {
        "tDiffuse": new THREE.Uniform(null),
        "u_resolution": new THREE.Uniform(new THREE.Vector2(1., window.innerHeight / window.innerWidth)),
        "u_mouse": new THREE.Uniform(new THREE.Vector2(-1, -1))
    },

    vertexShader: `
    precision lowp float;
    precision lowp int;

    varying vec2 vUv;
    void main() {

        vUv = uv;
        
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0 );
    
    }
    `,

    fragmentShader: `
    precision lowp float;
    precision lowp int;
    
    uniform sampler2D tDiffuse;
    uniform vec2 u_resolution;
    uniform vec2 u_mouse;
    varying vec2 vUv;

    float circle(vec2 uv, vec2 disc_center, float disc_radius, float border_size) {
      uv -= disc_center;
      uv*=u_resolution;
      float dist = sqrt(dot(uv, uv));
      return smoothstep(disc_radius+border_size, disc_radius-border_size, dist);
    }
    void main()  {
        vec2 newUV = vUv;
        float c = circle(vUv, u_mouse,0.0, 0.3);
        float r = texture2D(tDiffuse, newUV.xy += c * (0.1 * .1)).x;
        float g = texture2D(tDiffuse, newUV.xy += c * (0.1 * .125)).y;
        float b = texture2D(tDiffuse, newUV.xy += c * (0.1 * .15)).z;
        vec4 color = vec4(r, g, b, 1.);

        gl_FragColor = color;
    }
    `,

    resize(e) {
        this.uniforms.u_resolution.value = new THREE.Vector2(1.0, window.innerHeight / window.innerWidth)
    },

    mousemove(e) {

        this.uniforms.u_mouse.value = new THREE.Vector2((e.clientX / window.innerWidth), 1. - (e.clientY / window.innerHeight))
    }


}


export { MouseEffectShader }
