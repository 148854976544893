
export class Loader {

    constructor() {

        this.progress = 0

    }

    load(json = [], callback) {

        const f = []

        const n = 100 / json.length

        for (let i in json) {
            // if(r.status === 404){}

            f.push(
                fetch(json[i].url)
                    .then(r => {

                        this.progress += n

                        callback && callback(this.progress)

                        return r.blob()

                    })

            )

        }

        return Promise.all(f)
            .then(res => {

                return res.map((item, index) => {

                    return {
                        name: json[index].name,
                        type: json[index].type,
                        url: window.URL.createObjectURL(item),
                    }

                })

            })

    }

    getProgress() {

        return this.progress

    }

}