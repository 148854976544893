import * as THREE from 'three'

import { YFModule } from './YFModule';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'

import { MouseEffectShader } from "../shader"



// import {
//     EffectComposer,
// } from "postprocessing";

import { createPass } from '../createPass';

const mouseData = new THREE.Vector2()
export class Stage extends YFModule {

    constructor(context, config) {

        super(
            context,
            Object.assign({}, config || {})
        )

        this.renderer = null

        this.composer = null

        this.backgroundPasses = []

        this.currentPassIndex = null

        this.screenObjects = null

        this.mousePass = null

        this.init()

    }

    init() {

        const { canvas, deviceWidth, deviceHeight, devicePixelRatio } = this._config

        this.scene = new THREE.Scene();

        this.camera = new THREE.PerspectiveCamera(60, deviceWidth / deviceHeight, 0.01, 1000)
        this.camera.position.z = 1.0

        // this.camera = new THREE.OrthographicCamera(deviceWidth / - 2, deviceWidth / 2, deviceHeight / 2, deviceHeight / - 2, 1, 1000);
        // this.camera.position.z = 100.0
        // this.camera.zoom = 1000
        // this.camera.updateProjectionMatrix()

        const renderer = new THREE.WebGLRenderer({ canvas, alpha: false, antialias: false })

        const composer = new EffectComposer(renderer, new THREE.WebGLRenderTarget(deviceWidth, deviceHeight, {
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            format: THREE.RGBFormat,
            stencilBuffer: true
        }))


        const geometry = new THREE.PlaneGeometry(1.0, 1.0 * (1080 / 1920))

        this.screenObjects = new THREE.Object3D()

        this.renderer = renderer
        this.composer = composer


        const renderPass = new RenderPass(this.scene, this.camera)

        this.mousePass = new ShaderPass(MouseEffectShader)

        this.composer.addPass(renderPass)
        this.composer.addPass(this.mousePass)

        this.backgroundPasses = this._config.passes.map((v, index) => {

            const pass = createPass(v)

            const object = new THREE.Mesh(geometry, pass.material)

            object.visible = false

            this.screenObjects.add(object)

            pass.object = object

            return pass

        })

        this.scene.add(this.screenObjects);

        this.resize()

    }

    onAwake() {

        const { canvas, deviceWidth, deviceHeight, devicePixelRatio } = this._config

        this.renderer.setSize(deviceWidth, deviceHeight)
        this.renderer.setPixelRatio(devicePixelRatio)

        this.composer.renderer.setClearColor(new THREE.Color(.13, .13, .13))
        this.composer.renderer.setClearAlpha(1)

        this.passDispatch("load")

    }


    onStart() {


    }

    onPause() {

    }

    setCurrentPassIndex(index) {

        if (index === this.currentPassIndex) { return false }

        if (this.currentPassIndex !== null) {

            if (this.backgroundPasses[this.currentPassIndex] && this.backgroundPasses[this.currentPassIndex].setState(false)) {

                this.backgroundPasses[this.currentPassIndex].pause()

            }
        }

        if (this.backgroundPasses[index] && this.backgroundPasses[index].setState(true)) {

            this.backgroundPasses[index].start()
                .then(() => {

                    this.screenObjects.children.forEach((object, idx) => {
                        object.visible = (index === idx)
                    })

                })

        }

        this.currentPassIndex = index

        return true

    }

    render(state) {

        this.mousePass.uniforms.u_mouse.value.lerp(mouseData, .1)

        if (this.backgroundPasses && this.backgroundPasses[this.currentPassIndex]) {

            this.backgroundPasses[this.currentPassIndex].update(state.time)

        }


        this.composer.renderer.clear()

        this.composer.render(state.delta)

        this.passDispatch("render", state)

    }

    resize(e) {

        const w = window.innerWidth
        const h = window.innerHeight

        this._config.deviceWidth = w
        this._config.deviceHeight = h

        this.renderer.setSize(w, h)

        this.composer.setSize(w, h)

        // const ratio = Math.min((window.innerWidth / 1920), (window.innerHeight / 1080))
        // const ratio = Math.max((window.innerWidth / 1920), (window.innerHeight / 1080))
        // const ratio = Math.min((1920 / window.innerWidth), (1080 / window.innerHeight))
        // const ratio = Math.max((1920 / window.innerWidth), (1080 / window.innerHeight))

        // if (window.innerWidth / 1920 > window.innerHeight / 1080) {
        //     this.camera.fov = THREE.MathUtils.radToDeg(window.innerWidth / 1920)
        // } else {
        //     this.camera.fov = THREE.MathUtils.radToDeg(ratio)
        // }


        this.camera.aspect = window.innerWidth / window.innerHeight;

        const ratio = this.camera.aspect / (1920 / 1080)

        if (ratio >= 1) {

            this.camera.fov = THREE.MathUtils.radToDeg(Math.atan((window.innerHeight) / (window.innerWidth * ratio)))

        } else {

            this.camera.fov = THREE.MathUtils.radToDeg(Math.atan((window.innerHeight * ratio) / (window.innerWidth)))

        }

        // this.camera.setViewOffset(window.innerWidth, window.innerHeight, 0, 0, window.innerWidth, window.innerHeight)

        this.camera.updateProjectionMatrix()


        // this.screenObjects.scale.set(ratio, ratio, 1.0)

        this.mousePass.uniforms.u_resolution.value = new THREE.Vector2(1.0, window.innerHeight / window.innerWidth)

        this.passDispatch("resize", { w, h })

    }


    mousemove(e) {

        mouseData.set((e.clientX / window.innerWidth), 1. - (e.clientY / window.innerHeight))

        this.passDispatch("mousemove", e)

    }

    onDestroyed() {

        this.renderer = null

        this.composer = null

        this.backgroundPasses = []

    }

    passDispatch(key, value) {

        this.backgroundPasses.forEach(pass => {

            pass && pass[key](value)

        })

    }

}