
 import * as THREE from 'three'

import WaterGrainVertex from '../glsl/WaterGrain.vs';
import WaterGrainFragment from '../glsl/WaterGrain.fs';

export const WaterGrainShader = {

    uniforms: {

        "tDiffuse": { type: "t", value: null },
        "iResolution": { type: "v2", value: new THREE.Vector2() },
        "iTime": { type: "f", value: 0.0 },

    },

    vertexShader: WaterGrainVertex,

    fragmentShader: WaterGrainFragment

}