

import {
    ContextPass,
    MediaContextPass,
    CardContextPass,
    MixContextPass
} from './ContextPass';

export function createPass(config) {

    switch (config.type) {
        case -1:
            return null
        case 0:
            return new MediaContextPass(config)
        case 1:
            return new CardContextPass(config)
        case 2:
            return new MixContextPass(config)

    }

}